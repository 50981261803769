import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode from 'jwt-decode'
import { graphqlEndpoint } from 'src/config'
import { fetchAccessToken } from 'src/modules/api/refreshToken'
import { trackException } from 'src/modules/ga'
import { getAccessToken, setAccessToken } from 'src/modules/providers/AccessTokenProvider'

const authLink = setContext(() => {
  const accessToken = getAccessToken()
  if (!accessToken) return {}
  return {
    headers: { authorization: `bearer ${accessToken}` },
  }
})

const tokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken()
    if (!token) return true
    try {
      const { exp } = jwtDecode<{ exp: number }>(token)
      if (Date.now() >= exp * 1000) {
        return false
      } else {
        return true
      }
    } catch (error) {
      trackException('Unable to decode JWT token')
      return false
    }
  },
  fetchAccessToken,
  handleFetch: accessToken => setAccessToken(accessToken),
  handleError: error => console.error(error),
}) as any

const link = from([tokenLink, authLink, new HttpLink({ uri: graphqlEndpoint, credentials: 'include' })])

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})
