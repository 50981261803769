import React, { createContext, FC } from 'react'
import { io } from 'socket.io-client'
import { apiEndpoint } from 'src/config'

const socket = io(apiEndpoint || '', {
  autoConnect: false,
  withCredentials: true,
})

export type SocketInstance = typeof socket

export const SocketContext = createContext<SocketInstance>(socket)

export const RealtimeProvider: FC = ({ children }) => {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}
