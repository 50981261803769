import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react'

export type TokenContext = {
  accessToken?: string
  setAccessToken: (token?: string) => void
}

export const AccessTokenContext = createContext<TokenContext>({ accessToken: undefined, setAccessToken: () => void 0 })

let setAccessTokenFn: Dispatch<SetStateAction<string | undefined>>
let getAccessTokenFn: () => string | undefined

// This is needed as apollo client needs to set access token
export const setAccessToken = (token: string) => setAccessTokenFn(token)
export const getAccessToken = () => getAccessTokenFn()

export const AccessTokenProvider: FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>()
  setAccessTokenFn = setAccessToken
  getAccessTokenFn = () => accessToken
  const value = { accessToken, setAccessToken }
  return <AccessTokenContext.Provider value={value}>{children}</AccessTokenContext.Provider>
}
