export const isProd = process.env.NODE_ENV === 'production'
export const isDev = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'

export const previewMode = process.env.REACT_APP_PREVIEW === 'true'
export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
export const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT

export const helloSignConfig = {
  skipDomainVerification: process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN_VERIFICATION === 'true',
}

export const analyticsConfig = {
  id: process.env.REACT_APP_GTAG_TRACKING_ID,
}

export const useTestValues = process.env.REACT_APP_TEST_VALUES === 'true'

export const enableHumanAPI = process.env.REACT_APP_ENABLE_HUMAN_API === 'true'

export const enableHipaa = process.env.REACT_APP_ENABLE_HIPAA === 'true'

export const appEnv = process.env.REACT_APP_ENV || ''

export const enableHotjar = process.env.REACT_APP_ENABLE_HOTJAR === 'true'

export const supportedProviders = (process.env.REACT_APP_RESTRICT_PROVIDERS || 'MOO,AA,S').split(',')

export const supportedProducts = process.env.REACT_APP_RESTRICT_PRODUCTS
  ? process.env.REACT_APP_RESTRICT_PRODUCTS.split(',')
  : []

export const isEasyTermApp =
  supportedProviders.length === 1 &&
  supportedProviders[0] === 'AA' &&
  supportedProducts.length === 1 &&
  supportedProducts[0] === 'EZT'

export const placesAPIKey = process.env.REACT_APP_PLACES_API_KEY
