import { analyticsConfig } from 'src/config'

const trackingId = analyticsConfig.id as string

type TagCommand = 'config' | 'event'
type TrackPageView = [TagCommand, string, { page_path: string }]

type GATrack = TrackPageView
declare global {
  interface Window {
    dataLayer: GATrack[]
    gtag: (command: TagCommand, trackId: string, data: Record<string, unknown>) => void
  }
}

export const trackPageView = (path: string) => {
  window.gtag('config', trackingId, { page_path: path, page_location: window.location.href })
}

export const trackException = (error: unknown, fatal = false) => {
  const description = error instanceof Error ? `${error.name} - ${error.message}` : error
  window.gtag('event', 'exception', { description, fatal })
}

export const trackLogin = () => {
  window.gtag('event', 'login', { method: 'email' })
}

export const trackExplorePolicy = (policy: string) => {
  window.gtag('event', 'explore_policy', {
    event_category: 'policies',
    event_label: policy,
  })
}

export const trackApplyPolicy = (policy: string) => {
  window.gtag('event', 'apply_policy', {
    event_category: 'policies',
    event_label: policy,
  })
}

export const trackWizardStep = (name: string, action = 'next_step') => {
  window.gtag('event', action, {
    event_category: 'wizard',
    event_label: name,
  })
}
