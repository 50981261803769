import 'src/styles/index.generated.css'

import { ApolloProvider } from '@apollo/client'
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PageLoading } from 'src/components/PageLoading'
import { apolloClient } from 'src/modules/apolloClient'
import { initializeHotjar } from 'src/modules/hotjar'
import { AccessTokenProvider } from 'src/modules/providers/AccessTokenProvider'
import { RealtimeProvider } from 'src/modules/providers/RealtimeProvider'

import { enableHotjar, previewMode } from './config'
import * as serviceWorker from './serviceWorker'

const App = lazy(() => import('./pages/App'))
const Login = lazy(() => import('./pages/Login'))
const LoginConfirm = lazy(() => import('./pages/LoginConfirm'))
const Review = lazy(() => import('./pages/Review'))
const PreviewMode = lazy(() => import('./pages/PreviewMode'))

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe') // eslint-disable-line @typescript-eslint/no-var-requires
  axe(React, ReactDOM, 1000)
}

if (process.env.NODE_ENV === 'production') {
  // Add self-XSS warning
  console.log('%cStop!', 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;')
  console.log(
    '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your Inszu data.',
    'font-size: 18px;',
  )

  // Disable logging
  console.log = () => undefined
  console.warn = () => undefined
  console.error = () => undefined
  console.info = () => undefined
}

ReactDOM.render(
  <>
    {previewMode ? (
      <BrowserRouter>
        <Suspense fallback={<PageLoading />}>
          <Switch>
            <Route path="/" component={PreviewMode} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    ) : (
      <AccessTokenProvider>
        <ApolloProvider client={apolloClient}>
          <RealtimeProvider>
            <BrowserRouter>
              <Suspense fallback={<PageLoading />}>
                <Switch>
                  <Route path="/confirm-login" component={LoginConfirm} />
                  <Route path="/login" component={Login} />
                  <Route path="/agent-review" component={Review} />
                  <Route path="/" component={App} />
                </Switch>
              </Suspense>
            </BrowserRouter>
          </RealtimeProvider>
        </ApolloProvider>
      </AccessTokenProvider>
    )}
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Enable hotjar
if (enableHotjar) {
  initializeHotjar()
}
