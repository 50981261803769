import React, { FC } from 'react'

import { LoadingIndicator } from './LoadingIndicator'

export const PageLoading: FC = () => {
  return (
    <div className="flex-1 h-screen grid place-items-center">
      <LoadingIndicator />
    </div>
  )
}
