import { apiEndpoint } from 'src/config'

interface TokenResponse {
  accessToken: string
}

export const fetchAccessToken = () =>
  fetch(`${apiEndpoint}/auth/refresh_token`, {
    method: 'POST',
    credentials: 'include',
  })

export const refreshToken = (): Promise<TokenResponse> => fetchAccessToken().then(res => res.json())
