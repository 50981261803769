import lottie from 'lottie-web'
import React, { FC, useEffect, useRef, useState } from 'react'
import animData from 'src/assets/heart.json'

interface LoadingIndicatorProps {
  animHolderClasses?: string
}
export const LoadingIndicator: FC<LoadingIndicatorProps> = ({ animHolderClasses = 'h-12 w-12 md:h-16 md:w-16' }) => {
  const [animLoaded, setAnimLoaded] = useState(false)
  const animRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!animRef.current) return
    if (animLoaded) return
    lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animData,
    })
    setAnimLoaded(true)
  }, [animRef.current, animLoaded])
  return <div ref={animRef} className={animHolderClasses}></div>
}
