type HotjarFn = () => void

interface Hotjar extends HotjarFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  q?: any[]
}
declare global {
  interface Window {
    hj: Hotjar
    _hjSettings: {
      hjid: number
      hjsv: number
    }
  }
}

export function initializeHotjar() {
  window.hj =
    window.hj ||
    function (...args) {
      ;(window.hj.q = window.hj.q || []).push(args)
    }
  window._hjSettings = { hjid: 2191631, hjsv: 6 }
  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv
  head.appendChild(script)
}
